<template>
   <div class="useland box" :style="{width: width + 'px'}">
      <input @click="isShow = !isShow" :value="choose.class.id && choose.childClass.id ? choose.class.text + ' ' + choose.childClass.text : ''" class="form-control cursor-hand ellipsis" type="text" placeholder="选择土地用途" readonly="readonly">
      <em>
        <b-icon  @click="isShow = !isShow" icon="chevron-down"></b-icon>
      </em>
      <div v-if="isShow" class="city-select-warp">
        <div class="city-select-tab">
          <a
            @click="current = 0"
            class="tab-china"
            :class="{ current: current == 0 }"
            href="javascript:;"
            >用途大类</a
          >
          <a
            @click="current = 1"
            class="tab-china"
            :class="{ current: current == 1 }"
            href="javascript:;"
            >用途小类</a
          >
        </div>
        <div class="city-select-content">
          <div
            v-show="current == 0"
            class="city-select"
            style="display: block"
          >
            <dl>
              <dd>
                <a
                  v-for="(m, i) in dict.landUseType"
                  :key="i"
                  @click="onUseLandClick(m)"
                  href="javascript:;"
                  :class="{ current: choose.class.id == m.id }"
                  >{{ m.text }}</a
                >
              </dd>
            </dl>
          </div>
          <div v-show="current == 1" class="city-select">
            <dl v-if="choose.class">
              <dd>
                <a
                  v-for="(c, i) in choose.class.children"
                  :key="i"
                  @click="onUseLandChildClick(c)"
                  href="javascript:;"
                  :class="{ current: choose.childClass.id == c.id }"
                  >{{ c.text }}</a
                >
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
 
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isShow:false,
      current: 0,
      chooseValue: "",
      choose: {
        class: {},
        childClass: {}
      }
    };
  },
  props: {
    width: {
      type:Number,
      default: 200
    }
  },
  computed: {
    ...mapGetters(["dict"]),
  },
  mounted() {
    document.addEventListener('click',this.setBoxStatus,true);
  },
  destroyed() {
    document.removeEventListener('click',this.setBoxStatus,true);
  },
  methods: {
      onUseLandClick(a) {
        this.choose.class = a;
        this.current = 1;
      },
      onUseLandChildClick(c) {
          this.choose.childClass = c;
          this.isShow = false;
          this.$emit('onChange',this.choose)
      },
      setBoxStatus(e) {
        if(e.path.findIndex(x=> x.className == 'useland box') < 0) {
          this.isShow = false;
        }
      }
  }
};
</script>

<style scoped>
@import url("~@/assets/css/city.css");
</style>
