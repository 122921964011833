<template>
  <div class="home" v-if="area">
    <search-header :currentActive="0"></search-header>
    <div class="container" v-if="dict">
      <!--顶部搜索-->
      <div class="row mt-3">
        <div class="home-four pull-left">
          <div class="four-tits">
            <ul>
              <li
                class="cursor-hand"
                :class="{ active: current == 0 }"
                @click="current = 0"
              >
                <svg
                  t="1629648787627"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="44839"
                  width="24"
                  height="24"
                >
                  <path
                    d="M149.4016 256.0512a26.7264 26.7264 0 0 1-18.432-7.3728 26.81856 26.81856 0 0 1-0.9728-37.8368c68.2496-71.68 154.4704-123.2896 249.3952-149.0944 14.2336-3.8912 28.928 4.5056 32.8192 18.7904 3.8912 14.2336-4.5056 28.9792-18.7904 32.8192-85.504 23.296-163.1744 69.7344-224.6144 134.3488a26.69056 26.69056 0 0 1-19.4048 8.3456zM910.2336 274.2272c-7.5264 0-14.9504-3.1232-20.2752-9.2672-60.4672-69.9392-138.9056-121.0368-226.8672-147.7632a26.76224 26.76224 0 0 1-17.8176-33.3824 26.74176 26.74176 0 0 1 33.3824-17.8176c97.6384 29.6448 184.7296 86.3232 251.8016 163.9424a26.71104 26.71104 0 0 1-2.7648 37.7344c-5.0688 4.4032-11.264 6.5536-17.4592 6.5536zM521.5744 153.4976c-228.352 0-413.44 185.088-413.44 413.44s185.088 413.44 413.44 413.44 413.44-185.088 413.44-413.44-185.088-413.44-413.44-413.44z m150.784 402.688l-130.8672 179.5584a26.73664 26.73664 0 0 1-37.376 5.888 26.81344 26.81344 0 0 1-5.888-37.376l93.184-127.7952-198.4 29.7984c-10.7008 1.5872-21.2992-3.3792-26.88-12.6464-5.5808-9.2672-5.0176-20.992 1.3824-29.696l133.4272-180.7872a26.74176 26.74176 0 0 1 37.4272-5.632 26.74176 26.74176 0 0 1 5.632 37.4272l-95.0784 128.8704 197.888-29.696a26.7776 26.7776 0 0 1 26.8288 12.5952c5.5296 9.0624 5.0688 20.736-1.28 29.4912z"
                    fill="#57A5FF"
                    p-id="44840"
                    data-spm-anchor-id="a313x.7781069.0.i2"
                    class=""
                  ></path>
                </svg>
                <span class="ml-2 text-info">快速找地</span>
              </li>
              <li
                class="cursor-hand"
                :class="{ active: current == 1 }"
                @click="current = 1"
              >
                <svg
                  t="1629649106012"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="45130"
                  width="24"
                  height="24"
                >
                  <path
                    d="M514.2016 55.8592c-253.0816 0-458.24 205.1584-458.24 458.24s205.1584 458.24 458.24 458.24 458.24-205.1584 458.24-458.24-205.1584-458.24-458.24-458.24z m237.7728 607.5904l-132.5056 140.6976c-13.7728 14.6432-32.4608 22.784-52.5824 22.9376h-0.6144c-16.7424 0-32.512-5.632-45.3632-15.9232a96.2304 96.2304 0 0 1-54.4256 16.7424c-25.856 0-50.2272-10.0864-68.5056-28.3648l-112.0256-112.0256c-29.7472-29.7472-35.9424-74.1376-18.8928-110.1312l-37.5808-40.192c-31.1296-35.2768-67.9936-126.0544 15.1552-217.3952 42.1888-46.2848 96.6656-68.608 153.344-62.8224 44.6464 4.5568 86.9376 26.8288 116.224 60.0576 12.2368-12.4928 20.0704-20.48 20.3264-20.736 38.8608-38.4512 139.52-84.5824 244.4288 14.1824 98.0992 92.3648 32.768 214.784-15.4624 266.5984l-0.768 0.8192a72.79104 72.79104 0 0 1-10.752 85.5552z"
                    fill="#ff9f00"
                    p-id="45131"
                  ></path>
                  <path
                    d="M598.528 482.8672c-26.2656 26.4192-71.7312 62.464-123.136 61.0816-32.512-1.024-61.3888-16.64-85.7088-46.3872a41.02656 41.02656 0 0 1 2.0992-54.3744c26.3168-27.392 59.0848-61.1328 86.6304-89.344-20.8896-25.344-52.224-42.496-85.504-45.8752-28.416-2.8672-70.6048 2.7648-110.336 46.3872-71.9872 79.0528-19.1488 143.7696-14.848 148.7872l33.024 35.2768c15.7696-10.5984 34.3552-16.2816 53.8112-16.2816 25.856 0 50.2272 10.0864 68.5056 28.3648l112.0256 112.0256c29.5936 29.5936 35.8912 73.728 19.0976 109.6192 3.6352 2.4064 7.8336 3.6352 12.3904 3.7376 5.9904-0.0512 11.5712-2.4576 15.7184-6.8608l132.5056-140.6976a21.69856 21.69856 0 0 0-0.512-30.4128L598.528 482.8672z"
                    fill="#ff9f00"
                    p-id="45132"
                  ></path>
                  <path
                    d="M386.7648 586.752c-8.6528-8.6528-20.1216-13.3632-32.3072-13.3632s-23.7056 4.7616-32.3072 13.3632c-17.8176 17.8176-17.8176 46.7968 0 64.6144L434.176 763.392c8.6528 8.6528 20.1216 13.3632 32.3072 13.3632s23.6544-4.7616 32.3072-13.3632c17.8176-17.8176 17.8176-46.7968 0-64.6144L386.7648 586.752zM743.8848 347.8528c-95.5392-89.9584-169.9328-18.432-173.056-15.36-0.5632 0.6144-79.8208 81.3056-135.6288 139.3664 13.0048 13.568 26.7264 20.4288 41.7792 20.8896 37.5296 1.3312 76.4416-36.1984 91.3408-52.3264a41.22112 41.22112 0 0 1 29.2352-13.1072h0.8192c10.752 0 21.248 4.3008 28.8768 11.9296l101.0688 100.5568c15.1552-17.5104 94.976-117.1456 15.5648-191.9488z"
                    fill="#ff9f00"
                    p-id="45133"
                  ></path>
                </svg>
                <span class="ml-2 text-warning">发布信息</span>
              </li>
              <li
                class="cursor-hand"
                :class="{ active: current == 2 }"
                @click="current = 2"
              >
                <svg
                  t="1629649245271"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="45551"
                  width="24"
                  height="24"
                >
                  <path
                    d="M358.4512 664.576l218.2144-87.296 87.296-218.2144L448 448.6144 358.4512 664.576z m152.576-186.6752a33.68448 33.68448 0 1 1 0 67.3792 33.68448 33.68448 0 1 1 0-67.3792z"
                    fill="#28a745"
                    p-id="45552"
                  ></path>
                  <path
                    d="M511.0272 53.4016c-253.0816 0-458.24 205.1584-458.24 458.24s205.1584 458.24 458.24 458.24 458.24-205.1584 458.24-458.24-205.2096-458.24-458.24-458.24z m216.2176 285.3376l-105.8816 264.6528a33.536 33.536 0 0 1-18.5344 18.5856l-264.6528 105.8816a33.17248 33.17248 0 0 1-36.096-7.5264 33.18272 33.18272 0 0 1-7.0656-36.1984l108.5952-261.9392c3.3792-8.192 9.7792-14.592 17.9712-18.0224l261.9904-108.5952a33.28 33.28 0 0 1 36.1984 7.0656 33.3568 33.3568 0 0 1 7.4752 36.096z"
                    fill="#28a745"
                    p-id="45553"
                  ></path>
                </svg>
                <span class="ml-2 text-success">找地客户</span>
              </li>
            </ul>
          </div>
          <div class="four-conts">
            <div class="four-conts-tab four-kszd active" v-show="current == 0">
              <div class="clearfix">
                <div class="float-left areabox box">
                  <input
                    @click="tools.isAreaShow = !tools.isAreaShow"
                    class="form-control width-200 ellipsis cursor-hand"
                    :value="tools.areaValue"
                    type="text"
                    placeholder="选择地区"
                    readonly="readonly"
                  />
                  <em>
                    <b-icon  @click="tools.isAreaShow = !tools.isAreaShow"  icon="chevron-down"></b-icon>
                  </em>
                  <city
                    v-model="tools.isAreaShow"
                    @onChoose="onChooseNeedsArea"
                  ></city>
                </div>
                <div class="float-left ml-2">
                  <useland :width="200" @onChange="onUseLandChange"></useland>
                </div>
                <div class="float-left ml-2">
                  <dropdown
                    v-show="
                      tools.useLand != null &&
                      metreArray.includes(tools.useLand.class.text)
                    "
                    :datas="dict.areaRangeP.filter((x) => x.id != '')"
                    @onChange="onAreaRangPChange"
                    :width="200"
                    placeholder="选择面积"
                  ></dropdown>
                  <dropdown
                    v-show="
                      tools.useLand == null ||
                      (tools.useLand != null &&
                        !metreArray.includes(tools.useLand.class.text))
                    "
                    :datas="dict.areaRangeM.filter((x) => x.id != '')"
                    @onChange="onAreaRangMChange"
                    :width="200"
                    placeholder="选择面积"
                  ></dropdown>
                </div>
                <b-button
                  class="ml-2 width-200"
                  @click="onFindNeeds"
                  variant="success"
                  >找客户</b-button
                >
              </div>
              <dl class="mt-3 mb-0">
                <dt>地区</dt>
                <dd>
                  <router-link
                    :to="'/supply/index?province=' + m.code"
                    v-for="(m, i) in area.province"
                    :key="i"
                    target="_blank"
                    >{{ m.name }}</router-link
                  >

                </dd>
              </dl>

            </div>
            <div class="four-conts-tab four-fbxx active" v-show="current == 1">
              <h2>选择信息类型，快速找到买家、卖家！</h2>
              <div class="screen">
                <span>信息类型：</span>
                <a
                  class="p-2 mr-3"
                  :class="{ active: tools.typeId == m.id }"
                  @click="tools.typeId = m.id"
                  href="javascript:;"
                  v-for="(m, i) in tools.iType"
                  :key="i"
                  >{{ m.text }}</a
                >
              </div>
              <div class="clearfix mt-3 mb-0">
                <div class="float-left mt-2">发布地区：</div>
                <div class="float-left areabox box">
                  <input
                    @click="tools.isAreaShow = !tools.isAreaShow"
                    class="form-control width-300 ellipsis cursor-hand"
                    :value="tools.areaValue"
                    type="text"
                    placeholder="选择地区"
                    readonly="readonly"
                  />
                  <em>
                    <b-icon  @click="tools.isAreaShow = !tools.isAreaShow"    icon="chevron-down"></b-icon>
                  </em>
                  <city
                    v-model="tools.isAreaShow"
                    @onChoose="onChooseNeedsArea"
                  ></city>
                </div>
                <div class="float-left mt-2 ml-4">土地用途：</div>
                <div class="float-left box width-300">
                  <useland :width="300" @onChange="onUseLandChange"></useland>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <b-button
                  class="mt-3 width-300 text-white"
                  @click="onFindNeeds"
                  variant="warning"
                  >免费发布信息</b-button
                >
              </div>
            </div>
            <div class="four-conts-tab four-kszd active" v-show="current == 2">
              <div class="clearfix">
                <div class="float-left areabox box">
                  <input
                    @click="tools.isAreaShow = !tools.isAreaShow"
                    class="form-control width-200 ellipsis cursor-hand"
                    :value="tools.areaValue"
                    type="text"
                    placeholder="选择地区"
                    readonly="readonly"
                  />
                  <em>
                    <b-icon  @click="tools.isAreaShow = !tools.isAreaShow"   icon="chevron-down"></b-icon>
                  </em>
                  <city
                    v-model="tools.isAreaShow"
                    @onChoose="onChooseNeedsArea"
                  ></city>
                </div>
                <div class="float-left ml-2">
                  <useland :width="200" @onChange="onUseLandChange"></useland>
                </div>
                <div class="float-left ml-2">
                  <dropdown
                    v-show="
                      tools.useLand != null &&
                      metreArray.includes(tools.useLand.class.text)
                    "
                    :datas="dict.areaRangeP.filter((x) => x.id != '')"
                    @onChange="onAreaRangPChange"
                    :width="200"
                    placeholder="选择面积"
                  ></dropdown>
                  <dropdown
                    v-show="
                      tools.useLand == null ||
                      (tools.useLand != null &&
                        !metreArray.includes(tools.useLand.class.text))
                    "
                    :datas="dict.areaRangeM.filter((x) => x.id != '')"
                    @onChange="onAreaRangMChange"
                    :width="200"
                    placeholder="选择面积"
                  ></dropdown>
                </div>
                <b-button
                  class="ml-2 width-200"
                  @click="onFindNeeds"
                  variant="success"
                  >找客户</b-button
                >
              </div>
              <dl class="mt-3 mb-0">
                <dt>地区</dt>
                <dd>
                  <router-link
                    :to="'/needs?province=' + m.code"
                    v-for="(m, i) in area.province"
                    :key="i"
                    target="_blank"
                    >{{ m.name }}</router-link
                  >

                </dd>
              </dl>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container mt-3 p-0"
      v-if="dict && dict.banner && dict.banner.home.length > 0"
    >
        <a :href="dict.banner.home[0].url != '' ? dict.banner.home[0].url : 'javascript:;'" :title="dict.banner.home[0].title" :target="dict.banner.home[0].url != '' ? '_blank' : '_self'">
            <van-image
            width="100%"
            height="130"
            fit="cover"
            lazy-load
            :src="dict.banner.home[0].bannerPath"
            ></van-image>
        </a>
    </div>
    <div v-if="getLandTags.length > 0" class="container p-0 mt-3 mb-3">
      <div class="row">
        <div class="col-12">
          <div class="bg-white rounded border px-4 py-3">
            <div class="row">
              <div class="col-12 text-secondary">

                <router-link
                    :to="'/supply/index?tags=' +
                      m.parentId +
                      '&serviceTag=' +
                      m.id"
                    v-for="(m, i) in getLandTags"
                    :key="i"
                    target="_blank"

                >
                <span  class="text-secondary ml-2">{{ m.text }}
                </span>
                </router-link >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hotClassLandList.length > 0" class="bg-light">
      <div class="container">
        <div class="row pt-3 pb-2 align-items-center">
          <div
            @click="hotClassLandCurrent = 0"
            class="col-2 border-left menu-title px-3 py-0 cursor-default"
          >
            热门分类
          </div>
          <div class="col">
            <a
              class="mr-3 px-2 py-1"
              href="javascript:;"
              @click="hotClassLandCurrent = 1"
              :class="{ active: hotClassLandCurrent == 1 }"
              >农用地</a>
            <a
              class="mr-3 px-2 py-1"
              href="javascript:;"
              @click="hotClassLandCurrent = 2"
              :class="{ active: hotClassLandCurrent == 2 }"
              >农房</a>
            <a
                class="mr-3 px-2 py-1"
                href="javascript:;"
                @click="hotClassLandCurrent = 3"
                :class="{ active: hotClassLandCurrent == 3 }"
            >林地</a>
            <a
                class="mr-3 px-2 py-1"
                href="javascript:;"
                @click="hotClassLandCurrent = 4"
                :class="{ active: hotClassLandCurrent == 4 }"
            >其他土地</a>
          </div>
        </div>
        <div v-show="hotClassLandCurrent == 0" class="row mx-n4nnn pb-4">
          <div class="col-3" v-for="m in hotClassLandList" :key="m.id">
            <land :datas="m" :height="200"></land>
          </div>
        </div>
        <div v-show="hotClassLandCurrent == 1" class="row mx-n4nnn pb-4">
          <div class="col-3" v-for="m in hotNydLandList" :key="m.id">
            <land :datas="m" :height="200"></land>
          </div>
        </div>
        <div v-show="hotClassLandCurrent == 2" class="row mx-n4nnn pb-4">
          <div class="col-3" v-for="m in hotNfLandList" :key="m.id">
            <land :datas="m" :height="200"></land>
          </div>
        </div>

        <div v-show="hotClassLandCurrent == 3" class="row mx-n4nnn pb-4">
          <div class="col-3" v-for="m in hotLDLandList" :key="m.id">
            <land :datas="m" :height="200"></land>
          </div>
        </div>

        <div v-show="hotClassLandCurrent == 4" class="row mx-n4nnn pb-4">
          <div class="col-3" v-for="m in hotQTTDLandList" :key="m.id">
            <land :datas="m" :height="200"></land>
          </div>
        </div>
      </div>
    </div>
<!--
求租
-->
    <div v-if="hotNeedsList.length > 0" class="container pt-2">
      <div class="row">
        <div class="col-12 p-0">
          <ul class="hot-text cleafix">
            <li class="text-secondary" v-for="m of hotNeedsList" :key="m.id">
            <router-link :to="'/needs?needsType=' + m.needsType" target="_blank">
                <span>{{ getNeedsType(m.needsType) }}</span>
            </router-link>
              <router-link :to="'/needs/detail?id=' + m.id" target="_blank">
                {{ m.title.replace(getNeedsType(m.needsType) + " ", "") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="hotLandList.length > 0" class="container">
      <div class="row pt-4 pb-2 align-items-center">
        <div class="col-2 border-left menu-title px-3 py-0">热门预约</div>
      </div>
      <div class="row mx-n4nnn">
        <div class="col-3" v-for="m in hotLandList" :key="m.id">
          <land :datas="m" :height="200"></land>
        </div>
      </div>
    </div>

    <div v-if="likeLandList.length > 0" class="container">
      <div class="row pt-3 pb-2 align-items-center">
        <div class="col-2 border-left menu-title px-3 py-0">猜你喜欢</div>
      </div>
      <div class="row mx-n4nnn">
        <div class="col-3" v-for="m in likeLandList" :key="m.id">
          <land :datas="m" :height="200"></land>
        </div>
      </div>
    </div>

    <div class="container p-0 pb-4">
      <div v-if="actHot" class="row py-4">
        <div class="col-4">
          <div class="mb-4 border-left menu-title px-3 py-0">
            <router-link :to="'/news/detail?id=' + actHot.id" target="_blank">
              实时热点
            </router-link>
          </div>
          <div>
            <router-link
              :to="'/news/detail?id=' + actHot.id"
              :title="actHot.title"
              target="_blank"
            >
              <van-image
                width="360"
                height="224"
                fit="cover"
                lazy-load
                :src="actHot.thumbnail"
              />
            </router-link>
          </div>
          <div class="mt-3 text-truncate">
            <router-link
              :to="'/news/detail?id=' + actHot.id"
              :title="actHot.title"
              target="_blank"
            >
              {{ actHot.title }}
            </router-link>
          </div>
          <div class="ellipsis-3 height-70">
            <router-link
              class="text-secondary"
              :to="'/news/detail?id=' + actHot.id"
              :title="actHot.digest"
              target="_blank"
            >
              {{ actHot.digest }}
            </router-link>
          </div>
        </div>

        <div v-if="newsList.length > 0" class="col-4">
          <div class="mb-4 border-left menu-title px-3 py-0">
            <router-link to="/news/lists?id=6" target="_blank">
              土地快讯
            </router-link>
          </div>

          <div v-for="m in newsList" :key="m.id">
            <div class="row align-items-center">
              <div class="col">
                <router-link
                  :to="'/news/detail?id=' + m.id"
                  :title="m.title"
                  target="_blank"
                >
                  <svg
                    t="1629539456454"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="13825"
                    width="20"
                    height="20"
                  >
                    <path
                      d="M596.2 833.4c-26.5-14.8-48.3-35.1-63.2-58.7-14.1-23-21.3-48.9-21-75.2 0-30.9 10.3-60.9 29.5-86.5 15.7-19.7 36.5-36.5 60.5-48.7 30-15.5 54.1-38 69.7-65 12.6-21.7 16.2-44.9 19.4-65.3 0 0 1.2-4.9 2.3-6.3 62.9-118.9 225.8-37.4 225.8 264.3 0 16.4-2.6 32.9-7.1 48.5-11.8 39.7-38.5 75.8-75.2 102-121.3 117.9-240.6-17.7-240.7-9.1z"
                      fill="#FBDCDA"
                      p-id="13826"
                    ></path>
                    <path
                      d="M415 716.5c59.3 71.5 149 179.4 236 222 5.1 2.9 39 21.5 76.9 21.5 14.8 0 30.2-2.8 44.7-10.5l4.2-2.6c81.7-57.1 141.2-137.1 167.6-225.6 9.9-34.5 15.1-70 15.4-106-0.2-97-39.1-191.9-109.7-267.4-23.7-25-49.5-35.6-76.8-31.3-19.7 3.1-36.4 13.8-48.6 26.8-6.8-28.3-16.9-55.8-30.3-82.3-2-5.2-4.7-10.1-7.9-14.8C649 176.6 589.7 118 513.4 75.7c-21.5-10.7-54.3-16.7-79.3-6-29.3 12.6-44.1 50.8-46.8 67.4l-0.7 4c-5.3 33.8-10.3 65.6-26.3 93.1-20.8 36-53 65.9-93 86.6-53.1 27.1-99.7 64.7-135.4 109.5C87.5 489.4 64 558 64 628.1c-0.9 59.7 15.8 119.1 48.2 172.2 35.1 55.8 86.6 102.9 147.6 135.3 28.6 16.8 65.2 16.6 93.1-0.4 29.2-17.8 28.6-54.1 25.1-116.9-0.5-9.2-1-17.2-1.1-24.2-1.7-40.4 6.7-79 15.6-104.5 7.2 8.5 15.1 18 22.5 26.9z m-67.7-107.2c-32 30.2-55.6 114.4-52.5 186.3 0 6.5 0.5 15.9 1.1 26.5 0.6 10.9 1.8 31.7 1.5 47-47.6-26.5-86.8-63-113.5-105.4-25.3-41.3-38.3-87.9-37.7-135.1 0-55.5 18.5-109.5 53-155.3 28.1-35.4 65.7-65.5 108.7-87.5C361.7 358 405 317.6 433.1 269c22.6-38.9 29.1-80.6 34.9-117.4 0 0 2.1-8.8 4.1-11.4 61.9 34.4 110.9 83 141.7 140.6l5.2 9.8c14.6 28.5 24.5 58.6 29.5 88.5 2.2 15.6 3.4 31.4 3.5 46.8 0 5-0.9 12.3-1.7 18.9-1.9 16.5-2.8 37.4 10.1 49.6 8.4 9.4 30 10.3 30 10.3 46.3 0 92.9-106.9 93.5-108.3 0.3-0.7 0.8-1.4 1.4-2.3 0.7 0.7 1.5 1.5 2.3 2.4 57.9 61.8 89.9 139.6 90 218.5-0.3 29.5-4.6 59-12.7 87.2-21.3 71.3-69.2 136.2-135 183.3-5.9 1-23-3.2-37.5-11.3-73.8-36.2-156.9-136.3-211.9-202.5-50.3-60.5-65.4-78.7-93.7-78.7-10-0.2-25.1 2.6-39.5 16.3z"
                      fill="#EA3E31"
                      p-id="13827"
                    ></path></svg
                  ><span class="ml-1">{{ m.title }}</span>
                </router-link>
              </div>
            </div>
            <router-link
              :to="'/news/detail?id=' + m.id"
              target="_blank"
              :title="m.digest"
            >
              <div class="text-secondary border-bottom py-3 mb-3 text-truncate">
                {{ m.digest }}
              </div>
            </router-link>
          </div>
        </div>
        <div v-if="askList.length" class="col-4">
          <div class="mb-4 border-left menu-title px-3 py-0">
            <router-link to="/ask" target="_blank"> 土地问答 </router-link>
          </div>
          <div class="border rounded px-2">
            <div
              class="p-3"
              :class="{ 'border-bottom': askList.length > i + 1 }"
              v-for="(m, i) in askList"
              :key="m.id"
            >
              <div class="row align-items-center">
                <div class="col text-truncate">
                  <router-link :to="'/ask/detail?id=' + m.id" target="_blank"
                    >{{ m.title }}
                  </router-link>
                </div>
                <router-link
                  v-if="getAskClassValue(m.classId, m.childClassId)"
                  :to="
                    '/ask?id=' +
                    getAskClassValue(m.classId, m.childClassId).code
                  "
                  target="_blank"
                  class="col-4 text-right p-0"
                >
                  <span class="aw-question-tags text-success"
                    ><b-icon icon="tag" variant="success"></b-icon>
                    {{ getAskClassValue(m.classId, m.childClassId).name }}</span
                  >
                </router-link>
              </div>
              <div class="row align-items-center mt-1">
                <div class="col text-truncate">
                  <span class="color-fz">回答</span>
                  <span
                    class="ml-2 text-secondary"
                    v-if="m.latestAnswerContent"
                    v-html="m.latestAnswerContent.replace(/<[^>]*>/g, '')"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <customer-right></customer-right>

    <map-footer></map-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import city from "@/components/city/city.vue";
import useland from "@/components/useland/useland.vue";
import land from "@/components/base/land.vue";
import LandRight from "@/components/right/land-right.vue";
export default {
  components: {
    land,
    useland,
    city,
    LandRight,
  },
  data() {
    return {
      current: 0,
      tools: {
        iType: [
          {
            id: 1,
            text: "发布土地",
          },
          {
            id: 2,
            text: "登记需求",
          },
        ],
        isAreaShow: false,
        areaValue: "",
        typeId: 1,
        area: null,
        useLand: null,
        areaRangeP: null,
        areaRangeM: null,
      },
      metreArray: ["商业及住宅", "公共用地", "农房"],
      hotClassLandCurrent: 0,
      hotClassLandList: [],
      hotNydLandList: [],
      hotNfLandList: [],
      hotLDLandList:[],
      hotQTTDLandList:[],
      hotNeedsList: [],
      hotLandList: [],
      likeLandList: [],
      newsList: [],
      askList: [],
      actHot: null,
    };
  },
  computed: {
    ...mapGetters(["area", "dict", "banner"]),
    getTagValue() {
      return function (code, childcode) {
        let m = this.classList.find((x) => x.code == code);
        if (m) {
          let c = m.children.find((x) => x.code == childcode);
          if (c) {
            return c.name;
          } else {
            return m.name;
          }
        }
        return "";
      };
    },
    getNeedsType() {
      return function (code) {
        if (this.dict) {
          let m = this.dict.needsType.find((x) => x.id == code);
          if (m) {
            return m.text;
          }
        }
        return "";
      };
    },
    getAskClassValue() {
      return function (code, childcode) {
        if (this.dict) {
          let m = this.dict.askClassList.find((x) => x.code == code);
          if (m) {
            let c = m.children.find((x) => x.code == childcode);
            if (c) {
              return c;
            } else {
              return m;
            }
          }
        }
        return null;
      };
    },
    getLandTags() {
      if (this.dict && this.dict.landTags) {
        return this.dict.landTags.filter((x) => x.level == 2).slice(0, 36);
      }
      return [];
    },
    getLandUseChildType() {
      if (this.dict && this.dict.landUseType) {
        let arr = [];
        this.dict.landUseType.forEach((x) => {
          if (x.children) {
            arr.push(...x.children);
          }
        });
        return arr;
      }
      return [];
    },
  },
  methods: {
    init() {
      //热门土地分类
      this.$http
        .post(this.$api.supply.getNeedsForLetList, {
          param: {},
          size: 8,
          current: 2,
        })
        .then((res) => {
          this.hotClassLandList = res.result.records.map((x) => {
            let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
            return {
              id: x.id,
              fileUrl: f ? f.fileUrl : "",
              title: x.title,
              price: x.price,
              priceUnit: x.priceUnit,
              measureArea: x.measureArea,
              acreageUnit: x.acreageUnit,
              year: x.years,
              area: x.area,
            };
          });
        });

      this.$http
        .post(this.$api.supply.getNeedsForLetList, {
          param: {
            classId: 1,
          },
          size: 8,
          current: 2,
        })
        .then((res) => {
          this.hotNydLandList = res.result.records.map((x) => {
            let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
            return {
              id: x.id,
              fileUrl: f ? f.fileUrl : "",
              title: x.title,
              price: x.price,
              priceUnit: x.priceUnit,
              measureArea: x.measureArea,
              acreageUnit: x.acreageUnit,
              year: x.years,
              area: x.area,
            };
          });
        });

      this.$http
        .post(this.$api.supply.getNeedsForLetList, {
          param: {
            classId: 3,
          },
          size: 8,
          current: 1,
        })
        .then((res) => {
          this.hotNfLandList = res.result.records.map((x) => {
            let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
            return {
              id: x.id,
              fileUrl: f ? f.fileUrl : "",
              title: x.title,
              price: x.price,
              priceUnit: x.priceUnit,
              measureArea: x.measureArea,
              acreageUnit: x.acreageUnit,
              year: x.years,
              area: x.area,
            };
          });
        });
      this.$http
          .post(this.$api.supply.getNeedsForLetList, {
            param: {
              classId: 4,
            },
            size: 8,
            current: 1,
          })
          .then((res) => {
            this.hotLDLandList = res.result.records.map((x) => {
              let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
              return {
                id: x.id,
                fileUrl: f ? f.fileUrl : "",
                title: x.title,
                price: x.price,
                priceUnit: x.priceUnit,
                measureArea: x.measureArea,
                acreageUnit: x.acreageUnit,
                year: x.years,
                area: x.area,
              };
            });
          });

      this.$http
          .post(this.$api.supply.getNeedsForLetList, {
            param: {
              classId: 2,
            },
            size: 8,
            current: 1,
          })
          .then((res) => {
            this.hotQTTDLandList = res.result.records.map((x) => {
              let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
              return {
                id: x.id,
                fileUrl: f ? f.fileUrl : "",
                title: x.title,
                price: x.price,
                priceUnit: x.priceUnit,
                measureArea: x.measureArea,
                acreageUnit: x.acreageUnit,
                year: x.years,
                area: x.area,
              };
            });
          });

      //土地需求
      this.$http
        .post(this.$api.supply.getNeedsList, {
          param: {
            area: "",
          },
          size: 20,
          current: 1,
        })
        .then((res) => {
          if (res.code == 1) {
            this.hotNeedsList = res.result.records;
          }
        });

      //热门预约
      this.$http
        .post(this.$api.supply.getNeedsForLetList, {
          param: {
            classId: 1,
          },
          size: 4,
          current: 1,
        })
        .then((res) => {
          this.hotLandList = res.result.records.map((x) => {
            let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
            return {
              id: x.id,
              fileUrl: f ? f.fileUrl : "",
              title: x.title,
              price: x.price,
              priceUnit: x.priceUnit,
              measureArea: x.measureArea,
              acreageUnit: x.acreageUnit,
              year: x.years,
              area: x.area,
            };
          });
        });

      //猜你喜欢
      this.$http
        .post(this.$api.supply.getNeedsForLetList, {
          param: {
            classId: 1,
          },
          size: 4,
          current: 2,
        })
        .then((res) => {
          this.likeLandList = res.result.records.map((x) => {
            let f = x.userNeedsFiles.find((y) => y.uploadType == 1);
            return {
              id: x.id,
              fileUrl: f ? f.fileUrl : "",
              title: x.title,
              price: x.price,
              priceUnit: x.priceUnit,
              measureArea: x.measureArea,
              acreageUnit: x.acreageUnit,
              year: x.years,
              area: x.area,
            };
          });
        });

      //土地问答
      this.$http
        .post(this.$api.ask.getQuestionList, {
          param: {},
          size: 4,
        })
        .then((res) => {
          this.askList = res.result.records;
        });

      //土地快讯
      this.$http
        .post(this.$api.news.getList, {
          param: {
            contentType: 6,
            showMode: 1,
          },
          size: 3,
        })
        .then((res) => {
          this.newsList = res.result.records;
        });

      //读取实时热点
      this.$http
        .post(this.$api.news.getList, {
          param: {
            showMode: "2",
          },
          size: 1,
        })
        .then((res) => {
          this.actHot = res.result.records[0];
        });
    },
    onChooseNeedsArea(a) {
      this.tools.areaValue =
        a.province.name +
        (a.city.name == "市辖区" ? "" : " " + a.city.name) +
        " " +
        a.area.name;
      this.tools.area = a;
      this.tools.isAreaShow = false;
    },
    onUseLandChange(u) {
      this.tools.useLand = u;
    },
    onAreaRangPChange(r) {
      this.tools.areaRangeP = r;
    },
    onAreaRangMChange(r) {
      this.tools.areaRangeM = r;
    },
    onFindNeeds() {
      let url = "";
      if (this.tools.area) {
        url += "province=" + this.tools.area.province.code;
        url += "&city=" + this.tools.area.city.code;
        url += "&area=" + this.tools.area.area.code;
      }
      if (this.tools.useLand) {
        url +=
          (url == "" ? "" : "&") + "classid=" + this.tools.useLand.class.id;
        url += "&childclassid=" + this.tools.useLand.childClass.id;
      }
      if (
        this.tools.useLand != null &&
        this.metreArray.includes(this.tools.useLand.class.text)
      ) {
        if (this.tools.areaRangeP) {
          url += (url == "" ? "" : "&") + "arp=" + this.tools.areaRangeP.id;
        }
      } else {
        if (this.tools.areaRangeM) {
          url += (url == "" ? "" : "&") + "arm=" + this.tools.areaRangeM.id;
        }
      }

      if (this.current == 0) {
        window.open("/#/supply/index?" + url, "_blank");
      }
      if (this.current == 1) {
        if (this.tools.typeId == 1) {
          window.open("/#/user/land/add?" + url, "_blank");
        } else {
          window.open("/#/user/needs/add?" + url, "_blank");
        }
      }
      if (this.current == 2) {
        window.open("/#/needs?" + url, "_blank");
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
@import url("~@/assets/css/ask.css");
.screen .active {
  background: #00cc00;
  color: #ffffff;
  border-radius: 6px;
}
.mx-n4nnn {
  margin: 0 -30px;
}

.contain img {
  height: 200px;
  width: 100%;
  display: block;
  object-fit: cover;
}

.hot-text li:before {
  content: "•";
  color: #d8d8d8;
  line-height: 20px;
  margin-right: 10px;
}
.hot-text li a {
  color: #555;
}

.hot-text li a.active,
.hot-text li a.active span,
.hot-text li a:hover,
.hot-text li a:hover span {
  color: #00cc00;
}
.hot-text ul {
  width: 1140px;
}
.hot-text li {
  width: 25%;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin: 6px 0;
  padding: 0 20px 0 0px;
}
.hot-text li a span {
  border-right: solid 1px #d8d8d8;
  padding-right: 10px;
  margin-right: 10px;
  color: #333;
}

.home-four {
  width: 1140px;
  height: 225px;
  border: 1px solid #eee;
}
.home-four .four-tits {
  width: 180px;
  height: 260px;
  float: left;
}
.home-four .four-tits li {
  height: 75px;
  border-bottom: solid 1px #ededed;
  border-right: solid 1px #ededed;
  line-height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 25px 0 25px 40px;
  color: #262626;
  background-color: #f8f8f8;
}
.home-four .four-tits li i {
  width: 24px;
  height: 24px;
  float: left;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin-right: 10px;
}
.home-four .four-tits li.active {
  background: #fff;
  border-right-color: #fff;
}
.home-four .four-tits li.active span.text-info {
  color: #57a5ff;
}
.home-four .four-tits li.active span.text-warning {
  color: #ff9f00;
}
.home-four .four-tits li.active span.text-success {
  color: #48bd1c;
}
.home-four .four-tits li.active span.text-orange {
  color: #ff5a00;
}
.home-four .four-tits li span.text-info,
.home-four .four-tits li span.text-orange,
.home-four .four-tits li span.text-success,
.home-four .four-tits li span.text-warning {
  color: #262626;
}
.home-four .four-conts {
  width: 920px;
  min-width: 920px;
  height: 300px;
  float: left;
}
.home-four .four-conts .four-conts-tab {
  display: none;
}
.home-four .four-conts .four-conts-tab.active {
  display: block;
}
.home-four .four-kszd {
  padding: 25px 30px;
}
.home-four .four-kszd dl {
  float: left;
}
.home-four .four-kszd dl dt {
  font-size: 14px;
  margin-bottom: 5px;
  color: #262626;
}
.home-four .four-kszd dl dd {
  overflow: hidden;
  max-height: 90px;
}
.home-four .four-kszd dl dd a {
  float: left;
  line-height: 30px;
  margin-right: 13px;
}
.home-four .four-fbxx {
  padding: 30px 0 0 70px;
}
.home-four .four-fbxx h2 {
  font-size: 16px;
  color: #262626;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}
</style>
